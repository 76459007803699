import Vue from 'vue';
import VueRouter from 'vue-router';
import login from '../views/login.vue';
import regeist from '../views/regeist.vue';
import home from '../views/home/index.vue';
import Agreement from '../views/agreement.vue';
import Records from '../views/records.vue';
import Tc from '../views/tc.vue';
import Event from '../views/event.vue';
import Faq from '../views/faq.vue';
import Cert from '../views/cert.vue';
import About from '../views/about.vue';
import VipLevel from '../views/vipLevel.vue';
import MyInfo from '../views/myInfo.vue';
import Deposit from '../views/deposit.vue';
import RechargeHistory from '../views/rechargeHistory.vue';
import Withdraw from '../views/withdraw.vue';
import WithdrawHistory from '../views/withdrawHistory.vue';
import Transaction from '../views/transaction.vue';
import ChangePwdPage from '../views/changePwd.vue';
import ChangePayPwdPage from '../views/changePayPwd.vue';
import ChangeFullNamePage from '../views/changeFullName.vue';
import ChangePhonePage from '../views/changePhone.vue';

Vue.use(VueRouter);

const routes = [{
		path: '/',
		name: 'main',
		redirect: '/login',
		component: home,
		children: [{
			path: 'home',
			name: 'home',
			component: home,
			meta: {
				name: 'SETAFF',
			},
		}, ],
	}, {
		path: '/login',
		name: 'login',
		component: login,
		meta: {
			name: 'SETAFF',
		},
	}, {
		path: '/regeist',
		name: 'regeist',
		component: regeist,
		meta: {
			name: 'SETAFF',
		},
	}, {
		path: '/agreement',
		name: 'agreement',
		component: Agreement,
		meta: {
			name: 'SETAFF',
		},
	}, {
		path: '/records',
		name: 'records',
		component: Records,
		meta: {
			name: 'SETAFF',
		},
	}, {
		path: '/tc',
		name: 'tc',
		component: Tc,
		meta: {
			name: 'SETAFF',
		},
	}, {
		path: '/event',
		name: 'event',
		component: Event,
		meta: {
			name: 'SETAFF',
		},
	}, {
		path: '/faq',
		name: 'faq',
		component: Faq,
		meta: {
			name: 'SETAFF',
		},
	}, {
		path: '/cert',
		name: 'cert',
		component: Cert,
		meta: {
			name: 'SETAFF',
		},
	}, {
		path: '/about',
		name: 'about',
		component: About,
		meta: {
			name: 'SETAFF',
		},
	}, {
		path: '/vipLevel',
		name: 'vipLevel',
		component: VipLevel,
		meta: {
			name: 'SETAFF',
		},
	}, {
		path: '/myInfo',
		name: 'myInfo',
		component: MyInfo,
		meta: {
			name: 'SETAFF',
		},
	}, {
		path: '/deposit',
		name: 'deposit',
		component: Deposit,
		meta: {
			name: 'SETAFF',
		},
	}, {
		path: '/rechargeHistory',
		name: 'rechargeHistory',
		component: RechargeHistory,
		meta: {
			name: 'rechargeHistory',
		},
	}, {
		path: '/withdraw',
		name: 'withdraw',
		component: Withdraw,
		meta: {
			name: 'withdraw',
		},
	}, {
		path: '/withdrawHistory',
		name: 'withdrawHistory',
		component: WithdrawHistory,
		meta: {
			name: 'withdrawHistory',
		},
	}, {
		path: '/transaction',
		name: 'transaction',
		component: Transaction,
		meta: {
			name: 'transaction',
		},
	},
	{
		path: '/changePwd',
		name: 'changePwd',
		component: ChangePwdPage,
		meta: {
			name: 'ChangePwd',
		},
	},
	{
		path: '/changePayPwd',
		name: 'changePayPwd',
		component: ChangePayPwdPage,
		meta: {
			name: 'ChangePayPwd',
		},
	},
	{
		path: '/changeFullName',
		name: 'changeFullName',
		component: ChangeFullNamePage,
		meta: {
			name: 'ChangeFullName',
		},
	},
	{
		path: '/changePhone',
		name: 'changePhone',
		component: ChangePhonePage,
		meta: {
			name: 'ChangePhone',
		},
	},
];

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
});

export default router;